html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}
body {
  background: #000;
}
.buttons  {
  text-align: center;
  margin-top:2rem;
}
button  {
  display:inline-block;
  margin:0 1rem;
  background:#000;
  border:1px solid #333;
  border-radius:2px;
  font-family:'Courier New', Courier, monospace;
  padding:0.5rem 1rem;
  color:#7efb55;
  transition:all 0.3s;
}
button:focus,
button:hover  {
  background:#7efb55;
  border-color:#7efb55;
  color:#000;
}